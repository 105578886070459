.left {
  width: 40%;
  margin: 0;
  float: left;
  height: 100vh;
  background-color: #131313;
  color: white;
}

.right {
  width: 60%;
  margin: 0;
  float: left;
  height: 100vh;
  background-color: #131313;
  color: white;
}

.loading-center {
  border: 0px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
}
